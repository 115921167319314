<template>
  <div id="xparsing-job" class="page page-xparsing-jobs">
    <PageHeader
      :icon="$t(`${headerTitle}.ICON`)"
      :title="$t(`${headerTitle}.PARSING_TITLES`)"
      class="pt-2"
    />

    <Loading :value="loading" z-index="9999" />
    <div class="page-xparsing-jobs__container">
      <v-row
        v-if="jobsParsed.length > 0 && !uploadDialog"
        class="page-xparsing-jobs__return-upload ml-4 mt-4 mr-4"
      >
        <v-tooltip left transition="none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              x-large
              @click="backToUploader"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
          {{ $t("JOB.TOOLTIP.UPLOAD_JOB") }}
        </v-tooltip>
      </v-row>

      <v-row
        v-if="jobsParsed.length > 0 && !uploadDialog"
        class="page-xparsing-jobs__jobs-list ml-4 pt-4 mr-4"
      >
        <v-col cols="12" class="px-0">
          <div
            v-for="job in jobsParsed"
            class="page-xparsing-jobs__jobs-list-wrap ml-8"
            :key="job.id"
            :id="job.id"
          >
            <v-btn
              icon
              class="page-xparsing-jobs__job-close"
              @click.stop="removeJob(job)"
            >
              <v-icon color="#fff">mdi-close</v-icon>
            </v-btn>
            <v-tooltip right transition="none">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-bind="attrs"
                  v-on="on"
                  src="/img/job_icon.png"
                  class="page-xparsing-jobs__jobs-list-pic"
                  @click="openJobParsed(job)"
                >
              </template>
              {{ job.name }}
            </v-tooltip>
            <v-tooltip right transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  @click="openJobParsed(job)"
                >
                  {{ job.name }}
                </v-chip>
              </template>
              {{ job.name }}
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <div
        v-if="jobsParsed.length > 0 && !isEmptyJobParsed && !uploadDialog"
        class="page-xparsing-jobs__job-show ml-4 mr-4"
      >
        <div class="page-xparsing-jobs__job-content">
          <pre>{{ jobContent }}</pre>
        </div>
        <div class="page-xparsing-jobs__job-info">
          <div class="page-xparsing-jobs__job-info-container">
            <div class="page-xparsing-jobs__job-info-container-tabs">
              <div
                v-for="jobTab in jobTabs"
                :key="jobTab.id"
                @click="activeTab = jobTab.id"
                class="page-xparsing-jobs__job-info-container-tabs-tab"
                :class="{'page-xparsing-jobs__job-info-container-tabs-tab-active': activeTab === jobTab.id}"
                :style="{'background-color': jobTab.tabColor}"
              >
                {{ jobTab.id }}
              </div>
            </div>
          </div>
          <div class="page-xparsing-jobs__job-info-tab-wrapper">
            <div v-if="Object.keys(activeTabContent).length !== 0" class="pt-12">
              <!-- the beginning of the first level -->
              <div
                v-for="(level1, label1) in activeTabContent"
                :key="label1"
                class="age-xparsing-jobs__job-info-tab-level1-list ml-5"
              >
                <span class="page-xparsing-jobs__job-info-tab-level1-label">
                  {{ $t(`JOB.PARSING_CONTENT_LABELS.${label1}`).toUpperCase() }}
                </span>
                <span
                  v-if="Object.keys(level1).length === 0"
                  class="page-xparsing-jobs__job-info-tab-level1-value ml-2"
                >
                  <pre>-</pre>
                </span>
                <div v-else class="mt-2 mb-2">
                  <!-- the beginning of the second level -->
                  <div
                    v-for="(level2, label2) in level1"
                    :key="label2"
                    class="page-xparsing-jobs__job-info-tab-level2-list ml-5 "
                  >
                    <span class="page-xparsing-jobs__job-info-tab-level2-label pr-1 pl-1">
                      {{ $t(`JOB.PARSING_CONTENT_LABELS.${label2}`).toUpperCase() }}
                    </span>
                    <span class="page-xparsing-jobs__job-info-tab-level2-value ml-2">
                      <pre>{{ level2 }}</pre>
                    </span>
                  </div>
                  <!-- the end of the second level -->
                </div>
              </div>
              <!-- the end of the first level -->
            </div>
            <div v-else class="page-xparsing-jobs__job-info-tab-missed-data">
              <h3 class="text-center page-xparsing-jobs__job-info-tab-missed-data-title">
                {{ $t('COMMON.MISSING_DATA') }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="jobsParsed.length > 0 && isEmptyJobParsed && !uploadDialog"
        class="page-xparsing-jobs__job-hidden ml-4 mr-4"
      />

      <v-row
        v-if="jobsParsed.length === 0 || uploadDialog"
        class="page-xparsing-jobs_drag-n-drop ml-4 mt-4 mr-4 height-100"
      >
        <v-col cols="12" class="pl-0 pr-0 pt-0">

          <!-- drag & drop screen -->
          <div class="height-100 mt-2">
            <ranking-dropzone
              v-model="files"
              max-length="1"
              @change="checkFile"
              mode="job"
              module="ximpact"
              type-dropzone="upload"
              :validFile="validFile"
            >
              <template v-slot:button>
                <v-btn
                  depressed
                  large
                  color="success"
                  class="mt-5"
                  @click="parseJob"
                >
                  {{ $t('IMPACT.BUTTONS.PARSE_BUTTON') }}
                </v-btn>
              </template>
              <template v-slot:second-button>
                <v-btn
                  color="accent"
                  class="page-xparsing-jobs__button-upload-text d-flex ml-3"
                  @click="jobTextDialogOpened = true"
                >
                  {{ $t('UPLOAD.IMPACT_BUTTONS.FROM_TEXT') }}
                </v-btn>
              </template>
            </ranking-dropzone>
          </div>
        </v-col>
      </v-row>

      <v-dialog
        v-model="jobTextDialogOpened"
        :attach="'page-xparsing-jobs'"
        max-width="60vw"
        width="545px"
        class="page-xparsing-jobs__dialog-text-job"
      >
        <v-card class="page-xparsing-jobs__dialog-text-job-card">
          <v-card-title class="justify-center pt-6 pb-6 mt-5">
            <h2 class="page-xparsing-jobs__dialog-text-job-title">{{ $t('IMPACT.TITLES.ENTER_JOB') }}</h2>
          </v-card-title>
          <v-card-text class="page-xparsing-jobs__dialog-text-job_card-text-wrapper">
            <v-textarea
              v-model="jobText"
              outlined
              clear-icon="mdi-close"
              class="page-xparsing-jobs__dialog-text-job_card-text"
            />
          </v-card-text>
          <v-card-actions class="justify-center pb-6">
            <v-btn
              large
              class="page-xparsing-jobs__dialog-text-job-actions_cancel-button pl-5 pr-5 mr-2"
              @click="jobTextDialogOpened = false"
            >
              {{ $t('IMPACT.BUTTONS.CANCEL_BUTTON') }}
            </v-btn>
            <v-btn
              large
              class="page-xparsing-jobs__dialog-text-job-actions_validate-button pl-5 pr-5 ml-2"
              @click="parseJobText"
            >
              {{ $t('IMPACT.BUTTONS.VALIDATE') }}
            </v-btn>
          </v-card-actions>
        </v-card>           
      </v-dialog>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import Uploader from '@/components/Uploader'
import RankingDropzone from '@/components/RankingDropzone'
import Loading from '@/components/Loading'

import { mapActions, mapGetters } from 'vuex'

import jobTabs from './job.tabs.json'

export default {
  components: {
    PageHeader,
    Uploader,
    RankingDropzone,
    Loading,
  },
  data() {
    return {
      file: undefined,
      fileLenght: 0,
      filesNames: [],
      mode: '',
      maxFileSize: 31457280,
      pendingParsing: {},
      files: [],
      loading: false,
      uploadDialog: true,
      tab: '',
      activeTab: 'bi',
      jobTextDialogOpened: false,
      jobText: '',
      jobFileName: '',
      validFile: false,
    }
  },
  mounted() {
    this.defineMode()
  },
  watch: {
    activeTab(n) {
      this.showJobParsedResult({ job: this.jobParsedResult, activeTab: n })
    },
  },
  computed: {
    ...mapGetters({
      jobsParsed: 'job/getJobsParsed',
      jobParsedResult: 'job/getJobParsedResult',
      activeTabContent: 'job/getActiveTabContent',
    }),

    headerTitle() {
      return this.mode === 'job' ? 'JOB' : 'CV'
    },

    jobContent() {
      return this.jobParsedResult.results.content || ''
    },

    isEmptyJobParsed() {
      return this.isEmptyObject(this.jobParsedResult)
    },

    jobTabs() {
      return jobTabs.tabs
    },
  },
  methods: {
    ...mapActions({
      addJobsParsed: 'job/addJobsParsed',
      removeJobParsedResult: 'job/removeJobParsedResult',
      showJobParsedResult: 'job/showJobParsedResult',
      removeShownJob: 'job/removeShownJob',
    }),

    isEmptyObject(obj) {
      for (var i in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, i)) {
          return false
        }
      }

      return true
    },

    parseJobText() {
      this.loading = true
      const currentDate = new Date()
      const jobName = this.jobFileName || 'Job'
      this.files = [new File([this.jobText], `${jobName}.txt`, {
        type: "text/plain",
        lastModified: currentDate,
      })]
      this.jobTextDialogOpened = false

      this.parseJob()
    },

    hasArray(obj) {
      return !!JSON.stringify(obj).includes(':[')
    },

    checkValue(value) {
      if (value === 0) return true
      if (value instanceof Array) return value.length > 0 && value.map(v => this.checkValue(v)).indexOf(true) !== -1
      if (value instanceof Object) return Object.keys(value).length > 0 && Object.keys(value).map(k => this.checkValue(value[k])).indexOf(true) !== -1
      if (typeof value === 'string') return !!value.trim()

      return !!value
    },

    defineMode() {
      this.$route.path.includes('job') ? this.mode = 'job' : this.mode = 'cv'
    },

    async parseJob() {
      this.loading = true
      const files = Array.from(this.files)
      const formData = new FormData()
      formData.append('files', files[0])

      try {
        const { data } = await this.$services.xbi_pr.job.parse(formData)

        if (data && data.length) {
          const { name, results, status, status_message } = data[0]
          const id = `id${Math.random().toString(16).slice(2)}`
          this.$store.dispatch(
            'messenger/add',
            { type: status, code: 'XPARSING.PARSING_JOB_SUCCESSFULLY', message: status_message, time: 3000 }
          )
          this.addJobsParsed({ id, name, results })
          this.loading = false
          this.uploadDialog = false
          return
        }

        this.loading = false
      } catch(error) {
        this.loading = false
      }
    },

    checkFile () {
      let fileSize
      this.files.forEach(file => {
        const { size } = file
        fileSize = size ? size : 0
      })

      if (fileSize > 0 && fileSize < this.maxFileSize) {
        this.validFile = true;
        return;
      }

      this.$store.dispatch('messenger/add', 
        { type: 'error', code: 'DEFAULT_MESSAGE', message: this.$t('MESSAGE.CREATIVE.UNSUPPORTED_SIZE'), time: 5000 });
      
      this.validFile = false;
    },

    backToUploader() {
      this.showJobParsedResult(null)
      this.uploadDialog = true
    },

    removeJob(job) {
      this.removeJobParsedResult(job)
      const elems = this.$el.querySelectorAll('.page-xparsing-jobs__jobs-list-wrap')

      elems.forEach(elem => {
        if (!this.isEmptyObject(this.jobParsedResult) && job.id === this.jobParsedResult.id) {
          this.removeShownJob([])
          elem.classList.remove('active')
        }
      })
    },

    openJobParsed(job) {
      const elems = this.$el.querySelectorAll('.page-xparsing-jobs__jobs-list-wrap')
      elems.forEach(elem => {
        if (elem.id === job.id) {
          elem.classList.add('active')
          return
        }

        elem.classList.remove('active')
      })

      this.showJobParsedResult({ job, activeTab: 'bi' })
    },
  },
  beforeDestroy() {
    this.addJobsParsed([])
    this.showJobParsedResult(null)
  },
}
</script>

<style lang="scss">
.page-xparsing-jobs {
  position: relative;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin: 1px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.4);
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner {
    background-color: $color-yellow;
  }

  .v-dialog__container {
    display: block;
  }

  .v-dialog {
    overflow: hidden;
  }

  .v-dialog__content {
    position: absolute;
  }

  .v-dialog:not(.v-dialog--fullscreen) {
    height: 340px; 
  }
}

.page-xparsing-jobs__container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}

.page-xparsing-jobs__return-upload {
  .v-btn {
    background-color: $color-white;
    position: absolute;
    top: 32px;
    right: 32px;

    &:hover {
      background-color: $color-dark-blue;

      .v-icon {
        color: $color-white;
      }
    }
  }

  .v-btn__content .v-icon {
    color: $color-dark-blue;
  }
}

.page-xparsing-jobs__return-upload,
.page-xparsing-jobs__jobs-list {
  background-color: $color-yellow;
  flex: 0;
}

.page-xparsing-jobs__jobs-list-wrap {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  .v-chip {
    max-width: 100px;
  }

  .v-chip__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.active {
    .page-xparsing-jobs__jobs-list-pic {
      transform: scale(1);
      transition: all 350ms;
    }

    .page-xparsing-jobs__job-close {
      background-color: $color-dark-blue;
    }

    .v-chip {
      background-color: $color-dark-blue;
      color: $color-white;
    }
  }
}

.page-xparsing-jobs__job-show,
.page-xparsing-jobs__job-hidden {
  display: flex;
  overflow: hidden;
  background-color: $color-yellow;
}

.page-xparsing-jobs__job-show {
  height: 100%;
  justify-content: space-between;
}

.page-xparsing-jobs__job-hidden {
  height: 100%;
  overflow: hidden;
}

.page-xparsing-jobs__job-content,
.page-xparsing-jobs__job-info {
  margin: 20px;
  overflow: scroll;
}

.page-xparsing-jobs__job-info {
  width: 60%;
  margin-left: 0;
  &-container {
    z-index: 100;
    width: fit-content;
    max-width: 41vw;
    position: absolute;
    margin: -10px 0 0 10px;
    display: flex;
    justify-content: center;
    &-tabs {
      display: flex;
      border-radius: 6px;
      white-space: nowrap;
      box-shadow: 0 3px 5px rgb(0 0 0 / 30%);
      overflow: hidden;
      background-color: $color-white;
      width: 100%;
      &-tab {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        white-space: nowrap;
        outline: none;
        padding: 10px;
        font-weight: bold;
        text-transform: uppercase;
        transition: all 350ms;
        opacity: .5;
        color: $color-white;
        &:hover, &-active {
          opacity: 1;
        }
      }
    }
  }
}

.page-xparsing-jobs__job-content {
  width: 40%;
  padding: 20px;
  margin-right: 0;
  background-color: $color-white;
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}

.page-xparsing-jobs__job-close {
  background-color: $color-pink;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;

  &:hover {
    background-color: $color-pink-hover;
  }
}

.page-xparsing-jobs__jobs-list-pic {
  height: 80px;
  margin: 10px;
  width: auto;
  max-width: 100%;
  box-shadow: 0 6px 8px -2px rgb(0 0 0 / 50%);
  opacity: .9;
  transform: scale(.8);
  transition: all 350ms;
  cursor: pointer;

  &:hover {
    transform: scale(1);
    transition: all 350ms;
  }
}

.page-xparsing-jobs__job-info-tab-wrapper {
  background: $color-white;
  border-radius: 6px;
  min-height: 100%;
  padding-bottom: 3px;
}

.page-xparsing-jobs__job-info-tab-missed-data {
  padding: 30% 0 0;
}

.page-xparsing-jobs__job-info-tab-missed-data-title {
  letter-spacing: 1px;
  color: $color-grey;
}

.page-xparsing-jobs__job-info-tab-level2-list {
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.page-xparsing-jobs__job-info-tab-level1-label{
  background-color: $color-grey-light
}

.page-xparsing-jobs__job-info-tab-level2-label  {
  border: 2px solid $color-grey-light;
}

.page-xparsing-jobs__job-info-tab-level1-label,
.page-xparsing-jobs__job-info-tab-level2-label {
  padding: 0 4px;
  border-radius: 5px;
  color: $color-grey;
}

.page-xparsing-jobs__job-info-tab-level1-value,
.page-xparsing-jobs__job-info-tab-level2-value {
  color: $color-grey-middle;
  white-space: pre-wrap;

  pre {
    display: inline;
  }
}

.page-xparsing-jobs__button-upload-text.v-btn:not(.v-btn--round).v-size--default {
  margin-left: 8px;

  @media (max-width: 1280px) {
    margin-top: 12px;
    margin-left: 0;
  }
}

.page-xparsing-jobs__dialog-text-job-card.theme--light.v-card {
  background-color: $color-white;
  border: 1px solid $color-white;
  border-radius: 8px;
}

.page-xparsing-jobs__dialog-text-job-title {
  color: $color-dark-blue;
  font-size: 1.5rem;
}

.page-xparsing-jobs__dialog-text-job_card-text-wrapper.v-dialog--scrollable > .v-card > .v-card__text  {
  overflow: hidden;
}

.page-xparsing-jobs__dialog-text-job_card-text {
  background-color: $color-white;
  border-radius: 0;

  .v-input__slot {
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }
}

.page-xparsing-jobs__dialog-text-job-actions_cancel-button.theme--light.v-btn.v-btn--has-bg {
  background-color: $color-error;
  color: $color-white;

  &:hover {
    box-shadow: 0 0 8px $color-error-shadow;
  }
}

.page-xparsing-jobs__dialog-text-job-actions_validate-button.theme--light.v-btn.v-btn--has-bg {
  background-color: $color-success;
  color: $color-white;

  &:hover {
    box-shadow: 0 0 8px $color-success-shadow;
  }
}
</style>
